import React, { useEffect } from "react";
import "./reservationButton.scss";
import { Link } from "react-router-dom";
import { FaConciergeBell } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { getSideNavSelector } from "../../../../Recoil/themeModule";
const ReservationBotton = (props) => {
  const [sideNav, setSideNav] = useRecoilState(getSideNavSelector);

  useEffect(() => {
    if (sideNav) {
      document.getElementById("mySidenav").style.width = "100%";
      document.getElementById("menuStatus").style.display = "none";
      document.querySelector("body").classList.toggle("menu_activated");
      setTimeout(() => {
        document
          .querySelector("#showCAonClickBtn")
          .classList.add("CAActiveOnBtn");
      }, 250);
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    } else {
      document.getElementById("mySidenav").style.width = "0%";
      document.getElementById("menuStatus").style.display = "block";
      document.querySelector("body").classList.remove("menu_activated");
      document
        .querySelector("#showCAonClickBtn")
        ?.classList.remove("CAActiveOnBtn");
      document.getElementsByTagName("body")[0].style.overflowY = "scroll";
    }
  });

  const openNav = () => {
    setSideNav(true);
  };
  const closeNav = () => {
    setSideNav(false);
  };

  if (window.matchMedia("(max-width: 370px)").matches) {
    if (document.getElementById("resMobIcon")) {
      document.getElementById("resMobIcon").addEventListener("click", () => {
        var scrollToRes =
          document.getElementById("checkAvailability").offsetTop;
        window.scrollTo({ top: scrollToRes + 415, behavior: "smooth" });
      });
    }
  } else if (window.matchMedia("(max-width: 395px)").matches) {
    if (document.getElementById("resMobIcon")) {
      document.getElementById("resMobIcon").addEventListener("click", () => {
        var scrollToRes =
          document.getElementById("checkAvailability").offsetTop;
        window.scrollTo({ top: scrollToRes + 490, behavior: "smooth" });
      });
    }
  } else if (window.matchMedia("(max-width: 430px)").matches) {
    if (document.getElementById("resMobIcon")) {
      document.getElementById("resMobIcon").addEventListener("click", () => {
        var scrollToRes =
          document.getElementById("checkAvailability").offsetTop;
        window.scrollTo({ top: scrollToRes + 490, behavior: "smooth" });
      });
    }
  } else if (window.matchMedia("(max-width: 500px)").matches) {
    if (document.getElementById("resMobIcon")) {
      document.getElementById("resMobIcon").addEventListener("click", () => {
        var scrollToRes =
          document.getElementById("checkAvailability").offsetTop;
        window.scrollTo({ top: scrollToRes + 465, behavior: "smooth" });
      });
    }
  } else if (window.matchMedia("(min-width: 768px)").matches) {
    if (document.getElementById("resMobIcon")) {
      document.getElementById("resMobIcon").addEventListener("click", () => {
        var scrollToRes =
          document.getElementById("checkAvailability").offsetTop;
        window.scrollTo({ top: scrollToRes + 340, behavior: "smooth" });
      });
    }
  } else {
    if (document.getElementById("resMobIcon")) {
      document.getElementById("resMobIcon").addEventListener("click", () => {
        var scrollToRes =
          document.getElementById("checkAvailability").offsetTop;
        window.scrollTo({ top: scrollToRes - 120, behavior: "smooth" });
      });
    }
  }

  return (
    <>
      <div className="ReservationBtn">
        <div id="mySidenav" className="sidenav">
          <div
            className="close-container custom_close"
            title="Close"
            onClick={closeNav}
          >
            <div className="leftright"></div>
            <div className="rightleft"></div>
          </div>
          <div className="d-flex align-items-center cust-mrgn d-md-none">
            <div className="container text-center mt-md-5 mt-2 mt-xl-1 mt-lg-2">
              <div className="text-center text-white book-direct-title">
                BOOK DIRECT & SAVE
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-md-none d-xl-block d-lg-none for-mac-device">
          {/* <Link
            type="button"
            className="hm-reservationBtn"
            to="/reservations"
            target="_blank"
            onClick={() => {
              localStorage.setItem(
                "state",
                JSON.stringify({
                  roomNo: 1,
                  adultNo: 1,
                  startDate: new Date(),
                  endDate: new Date(Date.now() + 3600 * 1000 * 24),
                })
              );
            }}
          >
            VIEW RATES
          </Link> */}

          <button type="button" className="hm-reservationBtn" onClick={openNav}>
          VIEW RATES
            <span className="boerder-reservation"></span>
          </button>
        </div>
        {/* mobile view bell icon reservation  */}
        <div className="d-block d-lg-none for-xs-mob">
          <Link to="/reservations" target="_blank">
            <FaConciergeBell
              size="50"
              className="mob-hm-reservation"
              id="resMobIcon"
            />
            <label className="d-none">bell icon reservation</label>
          </Link>
        </div>
        {/* mobile view bell icon reservation  */}
      </div>
    </>
  );
};

export default ReservationBotton;
