// export const headerConfiguration = (configObject) => {
//   configObject = {
//     ...configObject,
//     property_id: sessionStorage && sessionStorage.getItem("propertyId"),
//   };
//   if (configObject?.isCheck) {
//     return configObject;
//   }

//   return configObject;
// };

export const headerConfiguration = (configObject) => {
  console.log("configObject--", configObject);
  configObject = {
    ...configObject,
    property_id:
      (sessionStorage && sessionStorage.getItem("propertyId")) ||
      configObject.property_id,
  };
  if (configObject?.isCheck) {
    return configObject;
  }

  return configObject;
};
