// import axios from "axios";
// import { headerConfiguration } from "./headersConfiguration";
// const headers = {
//   "Content-Type": "multipart/form-data",
// };
// export const postAPICall = (url, data = null) => {
//   let parameters = headerConfiguration(data);
//   return axios.post(url, parameters, { headers: headers });
// };

import axios from "axios";
import { headerConfiguration } from "./headersConfiguration";
const headers = {
  "Content-Type": "multipart/form-data",
};
export const postAPICall = (url, data = null) => {
  let parameters = headerConfiguration(data);
  return axios.post(url, parameters, { headers: headers });
};

export const postApiCallReviews = (url, reviewData) => {
  console.log("reviews data-->", reviewData);
  return axios.post(url, reviewData, { headers: headers });
};

export const postApiCallPopupHomepage = (url, reviewData) => {
  console.log("reviews data-->", reviewData);
  return axios.post(url, reviewData, { headers: headers });
};
