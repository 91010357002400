import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import "./MainFooter.scss";
import logoone from "../assets/Icons/ywl.webp";
import logoonePNG from "../assets/Icons/ywl1.png";
import { AiFillGoogleCircle } from "react-icons/ai";
import TChoice from "../assets/Icons/tchoice.webp";
import TChoicePng from "../assets/Icons/tchoicePNG.png";
import TripAdv from "../assets/Icons/winner_certificates.jpg";
import TripAdvWebp from "../assets/Icons/winner_certificatesWebp.webp";
import pdf from "../../CommonAssets/Documents/download_info_sheet.pdf";
import { useRecoilValue, useRecoilState } from "recoil";
import LanguageTranslate from "../../../languageTest";
import {
  propertyDataSelector,
  themeSelector,
  getSideNavSelector,
} from "../../../Recoil/themeModule";
import {
  FaFacebookF,
  FaYoutube,
  FaSkype,
  FaInstagram,
  FaYelp,
  FaTripadvisor,
  FaFoursquare,
  FaPinterestP,
  FaLinkedinIn,
  FaYahoo,
  FaWheelchair,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
const MainFooter = (allpropertydata) => {
  const propertyData = useRecoilValue(propertyDataSelector);
  const sideNav = useRecoilValue(getSideNavSelector);
  const pathname = useLocation();
  let propData = allpropertydata.propertyData;
  let alttitleImgfoot = `${propData.property_name} - ${propData.street_address}, ${propData.city}, ${propData.state}, ${propData.zip_code}`;
  const [themeData, setThemeData] = useRecoilState(themeSelector);

  let dtitle = `Get Directions For ${propertyData.property_name}`;
  let dPhone = `${
    propertyData &&
    propertyData.reservation_phone_no &&
    propertyData.reservation_phone_no.split("/")[1]
  }`;

  let dfax = `${
    propertyData &&
    propertyData.facsimile_phone_no &&
    propertyData.facsimile_phone_no.split("/")[1]
  }`;

  let dReser = `${
    propertyData &&
    propertyData.primary_phone_no &&
    propertyData.primary_phone_no.split("/")[1]
  }`;

  // H1 Scroll Down Code start
  const waveerro = document.querySelectorAll("h2.mb-3");

  //check domain and update theme Name

  for (let i = 0; i < waveerro.length; i++) {
    waveerro[i].textContent = "Yosemeite";
    waveerro[i].classList.add("d-none");
  }

  // pm-edit h1scroll
  const h1scroll = () => {
    const element = document.querySelector("h1");
    if (element) {
      if (window.screen.availWidth > 1199) {
        let info1topoffset = element.offsetTop;
        let checkAvail =
          document.querySelector("#showCAonClickBtn").offsetHeight || 115;
        window.scrollTo({
          top: info1topoffset - checkAvail - 10,
          behavior: "smooth",
        });
      } else {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  useEffect(() => {
    if (sideNav === false && pathname.pathname !== "/") {
      setTimeout(() => {
        h1scroll();
      }, 2000);
    }
  }, [pathname.pathname]);
  // pm-edit h1scroll

  // Sojern Script - PM edits
  useEffect(() => {
    setTimeout(() => {
      import("../../../Sojern.js").then(() => {
        console.log("Sojern.js imported");
      });
    }, 10000);
  }, []);
  // Sojern Script - PM edits

  const actionUrl =
    themeData.url.includes("www") || themeData.url.includes("lbeta")
      ? "https://my.innsight.com/reservation-status"
      : "https://my.innstaging.com/reservation-status";

  return (
    <div className="Footer-Style row mx-0 foter-delay footer-component common-footer">
      <div className="container mb-0">
        <div className="col-12 Footer-logo text-center">
          <Link to="/">
            <picture className="desktopImage w-100">
              <source
                className="location-img w-100"
                type="image/webp"
                srcSet={logoone}
              ></source>
              <source
                className="location-img w-100"
                type="image/png"
                srcSet={logoonePNG}
              ></source>
              <img
                src={logoonePNG}
                className="mt-4"
                height="159"
                width="152"
                alt={alttitleImgfoot}
                title={alttitleImgfoot}
              />
              <figcaption className="foot-logo-caption">
                by BLINK HOTELS<sup>TM</sup>
              </figcaption>
            </picture>
          </Link>
        </div>

        {pathname.pathname === "/" ? (
          ""
        ) : (
          <div className="my-3 d-flex justify-content-center mt-5 mt-md-4 mt-lg-4 mt-xl-4 flex-column flex-lg-row align-items-center ftaddr">
            <span className="text-center">
              <span
                className="loc-subTitle py-2 py-md-2 py-xl-0 py-lg-0"
                title={dtitle}
              >
                {propertyData?.street_address},&nbsp;{propertyData?.city}
                ,&nbsp;
                {propertyData?.state},&nbsp;{propertyData?.zip_code},&nbsp;
                {propertyData?.country}
              </span>
            </span>
            <span className="mx-2 d-none d-lg-block"> | </span>
            <span className="pt-4 pb-2 pt-md-4 pb-md-2 py-xl-0 py-lg-0">
              Phone:&nbsp;
              <a
                className="text-decoration-none text-dark"
                title={dPhone}
                href={`tel:${
                  propertyData &&
                  propertyData.primary_phone_no &&
                  propertyData.primary_phone_no.split("/")[1]
                }`}
              >
                {propertyData &&
                  propertyData.primary_phone_no &&
                  propertyData.primary_phone_no.split("/")[1]}
              </a>
            </span>
            <span className="mx-2 d-none d-lg-block"> | </span>
            <span className="py-2 py-md-2 py-xl-0 py-lg-0">
              Reservations:&nbsp;
              <a
                className="text-decoration-none text-dark"
                title={dReser}
                href={`tel:${
                  propertyData &&
                  propertyData.reservation_phone_no &&
                  propertyData.reservation_phone_no.split("/")[1]
                }`}
              >
                {propertyData &&
                  propertyData.reservation_phone_no &&
                  propertyData.reservation_phone_no.split("/")[1]}
              </a>
            </span>
            <span className="mx-2 d-none d-lg-block"> | </span>
            <span className="py-2 py-md-2 py-xl-0 py-lg-0">
              Fax:&nbsp;
              <a
                className="text-decoration-none text-dark"
                title={dfax}
                href={`tel:${
                  propertyData &&
                  propertyData.facsimile_phone_no &&
                  propertyData.facsimile_phone_no.split("/")[1]
                }`}
              >
                {propertyData &&
                  propertyData.facsimile_phone_no &&
                  propertyData.facsimile_phone_no.split("/")[1]}
              </a>
            </span>
          </div>
        )}

        <div className="Footer-content row mx-0">
          <div className="row col-7 Footer-mob FooterAllLinks px-0">
            <div className="Footer-links col-4 px-0">
              <ul className="list-unstyled">
                {!allpropertydata.overview_status && (
                  <li>
                    <Link to="/overview" title="About Us">
                      About us
                    </Link>
                  </li>
                )}

                {!propertyData.job_status === "1" ? (
                  <li>
                    <Link to="/jobs" title="Jobs">
                      Jobs
                    </Link>
                  </li>
                ) : (
                  ""
                )}

                {propertyData.faq_status === "1" ? (
                  <li>
                    <Link to="/faq" title="FAQs">
                      FAQs
                    </Link>
                  </li>
                ) : (
                  ""
                )}

                {propertyData.is_personal_info === "1" && (
                  <li>
                    <Link
                      to="/personal-info-request-form"
                      title="Personal Data Request"
                    >
                      Personal Data Request
                    </Link>
                  </li>
                )}

                {propertyData.news_status === "1" && (
                  <li>
                    <Link to="/news" title="News">
                      News
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/contact-us" title="Contact Us">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="Footer-links col-4 px-0 rowAdjust">
              <ul className="list-unstyled">
                {propertyData.job_status === "1" && (
                  <li>
                    <Link to="/jobs" title="Jobs">
                      Jobs
                    </Link>
                  </li>
                )}
                {propertyData.blogStatus === "1" && (
                  <li>
                    <Link to="/blog" title="Blog">
                      Blog
                    </Link>
                  </li>
                )}

                {/* Group RFP is static page -> Don't have any swtich to toggle from admin  */}

                <li>
                  <Link to="/six-plus-booking" title="Groups">
                    Groups
                  </Link>
                </li>

                {propertyData.lost_and_found_status === "1" && (
                  <li>
                    <Link to="/lost-found" title="Lost & Found">
                      Lost & Found
                    </Link>
                  </li>
                )}
                {/*  Download Info Sheet is static page -> Don't have any swtich to toggle from admin  */}
                <li>
                  <a
                    href={pdf}
                    target="_blank"
                    rel="noreferrer"
                    className="infoSheet"
                    title="Download Info Sheet"
                  >
                    Download Info Sheet
                  </a>
                </li>
              </ul>
            </div>
            <div className="Footer-links col-4 px-0">
              <ul className="list-unstyled">
                <li>
                  <Link className="info_collect" to="/sitemap" title="Sitemap">
                    Sitemap
                  </Link>
                </li>

                {propertyData.is_cookies_policy === "1" && (
                  <li>
                    <Link to="/cookies" title="Cookie Policy">
                      Cookie Policy
                    </Link>
                  </li>
                )}
                {propertyData.is_privacy_policy === "1" && (
                  <li>
                    <Link to="/privacy-policy" title="Privacy Policy">
                      Privacy Policy
                    </Link>
                  </li>
                )}
                {propertyData.is_terms_cond === "1" && (
                  <li>
                    <Link to="/terms-conditions" title="Terms & Conditions">
                      Terms & Conditions
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="Footer-social col-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 awardAdjust">
            <div className="row Footer-social-content">
              <div className="certificate row">
                <div className="col-6 col-xl-5 col-lg-5 col-md-12 col-sm-12 Footer-btns footerBtnsDiv">
                  <div className="cust-align-end modifyButton text-decoration-none">
                    <label
                      className="d-none sr-only"
                      htmlFor="cancel_reservation2"
                      id="cancel_reservation2"
                    >
                      hidden label
                    </label>
                    <form
                      method="post"
                      name="cancel_reservation1"
                      id="cancel_reservation2"
                      action={actionUrl}
                    >
                      <a
                        href="javascript:document.cancel_reservation1.submit();"
                        className="modify BGModify text-decoration-none mt-0 px-2"
                        title="Modify/Cancel"
                      >
                        Modify / Cancel
                      </a>

                      {propertyData.property_id == "265" ? (
                        <input type="hidden" name="theme" value="yosemite" />
                      ) : (
                        <input
                          type="hidden"
                          name="theme"
                          value={propertyData.propertyname}
                        />
                      )}
                      <input
                        type="hidden"
                        name="property_id"
                        value={propertyData.property_id}
                      />
                      <input
                        type="hidden"
                        name="source_type"
                        value="Standalone"
                      />
                    </form>
                  </div>
                  <div className="mt-md-0 footer-btns">
                    {propertyData.accessibilty_status === "1" && (
                      <Link
                        to="/ada-accessibility-amenities-services-facilities"
                        title="Accessibility"
                        className="wheelchair text-decoration-none my-3 px-1"
                      >
                        <FaWheelchair size="18" className="on-h-Icon" />{" "}
                        <span className="wheel-text text-decoration-none">
                          Accessibility
                        </span>
                      </Link>
                    )}
                  </div>
                  <div className="cust-align-end PR lang-btn mt-3 mb-0">
                    <LanguageTranslate />
                    <label
                      htmlFor="goog-gt-votingInputSrcLang"
                      className="d-none"
                    >
                      one
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputTrgLang"
                      className="d-none"
                    >
                      two
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputSrcText"
                      className="d-none"
                    >
                      three
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputTrgText"
                      className="d-none"
                    >
                      four
                    </label>
                    <label htmlFor="goog-gt-votingInputVote" className="d-none">
                      five
                    </label>
                  </div>
                  <div className="cust-align-end PR lang-btn mt-3 mb-0">
                    <label
                      htmlFor="goog-gt-votingInputSrcLang"
                      className="d-none"
                    >
                      one
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputTrgLang"
                      className="d-none"
                    >
                      two
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputSrcText"
                      className="d-none"
                    >
                      three
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputTrgText"
                      className="d-none"
                    >
                      four
                    </label>
                    <label htmlFor="goog-gt-votingInputVote" className="d-none">
                      five
                    </label>
                  </div>
                </div>
                <div className="trip_card col-xl-7 col-lg-7 col-md-12 col-sm-12 mt-3 mb-3">
                  <div className="traveler-choice">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.tripadvisor.com/Hotel_Review-g32460-d77135-Reviews-Yosemite_Westgate_Lodge-Groveland_California.html"
                    >
                      <picture className="desktopImage w-100">
                        <source
                          rel="preload"
                          className=""
                          type="image/webp"
                          srcSet={TChoice}
                        ></source>
                        <source
                          className=""
                          rel="preload"
                          type="image/jpeg"
                          srcSet={TChoicePng}
                        ></source>
                        <img
                          rel="preload"
                          className="trip-advsr p-1"
                          src={TChoicePng}
                          alt="Traveler's Choice Winner"
                          title="Traveler's Choice Winner"
                          width="100%"
                          height="100%"
                        />
                      </picture>
                    </a>
                  </div>
                  <div className="traveler-choice">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.tripadvisor.in/Hotel_Review-g32460-d77135-Reviews-Yosemite_Westgate_Lodge-Groveland_California.html"
                    >
                      <picture className="desktopImage w-100">
                        <source
                          rel="preload"
                          className=""
                          type="image/webp"
                          srcSet={TripAdvWebp}
                        ></source>
                        <source
                          className=""
                          rel="preload"
                          type="image/png"
                          srcSet={TripAdv}
                        ></source>
                        <img
                          rel="preload"
                          className="trip-advsr p-1"
                          src={TripAdv}
                          alt="Certificate of Excellence"
                          title="Certificate of Excellence"
                          width="100%"
                          height="100%"
                        />
                      </picture>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {propData.facebook ||
        propData.google_url ||
        propData.twitter ||
        propData.youtube_url ||
        propData.yelp ||
        propData.tripadvisor ||
        propData.foresquare_code ||
        propData.pin_interest ||
        propData.instagram_url ||
        propData.linkedin_url ||
        propData.yahoo_url ||
        propData.skype ? (
          <hr className="draw-line mob-noLine ipadLine"></hr>
        ) : null}

        {propData.facebook ||
        propData.google_url ||
        propData.twitter ||
        propData.youtube_url ||
        propData.yelp ||
        propData.tripadvisor ||
        propData.foresquare_code ||
        propData.pin_interest ||
        propData.instagram_url ||
        propData.linkedin_url ||
        propData.yahoo_url ||
        propData.skype ? (
          <div className="social-icons col-12">
            {propData.facebook && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--facebook"
                href={propData.facebook}
                target="_blank"
              >
                <FaFacebookF size="50" className="text-center" />
                <div className="tooltip">Facebook</div>
              </a>
            )}

            {propData.google_url && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--googleplus"
                href={propData.google_url}
                target="_blank"
              >
                <AiFillGoogleCircle size="50" className="text-center" />
                <div className="tooltip">Google</div>
              </a>
            )}

            {propData.twitter && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--twitter"
                href={propData.twitter}
                target="_blank"
              >
                <FaXTwitter size="50" className="text-center" />
                <div className="tooltip">Twitter</div>
              </a>
            )}

            {propData.youtube_url && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--yTube"
                href={propData.youtube_url}
                target="_blank"
              >
                <FaYoutube size="50" className="text-center" />
                <div className="tooltip">YouTube</div>
              </a>
            )}

            {propData.yelp && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--yelp"
                href={propData.yelp}
                target="_blank"
              >
                <FaYelp size="50" className="text-center" />
                <div className="tooltip">Yelp</div>
              </a>
            )}

            {propData.tripadvisor && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--trip"
                href={propData.tripadvisor}
                target="_blank"
              >
                <FaTripadvisor size="50" className="text-center" />
                <div className="tooltip">TripAdvisor</div>
              </a>
            )}

            {propData.foresquare_code && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--foreSquare"
                href={propData.foresquare_code}
                target="_blank"
              >
                <FaFoursquare size="50" className="text-center" />
                <div className="tooltip">Foursquare</div>
              </a>
            )}

            {propData.pin_interest && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--pinInterest"
                href={propData.pin_interest}
                target="_blank"
              >
                <FaPinterestP size="50" className="text-center" />
                <div className="tooltip">Pinterest</div>
              </a>
            )}

            {propData.instagram_url && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--instagram"
                href={propData.instagram_url}
                target="_blank"
              >
                <FaInstagram size="50" className="text-center" />
                <div className="tooltip">Instagram</div>
              </a>
            )}

            {propData.linkedin_url && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--linkedin"
                href={propData.linkedin_url}
                target="_blank"
              >
                <FaLinkedinIn size="50" className="text-center" />
                <div className="tooltip">Linkedin</div>
              </a>
            )}

            {propData.yahoo_url && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--yahoo"
                href={propData.yahoo_url}
                target="_blank"
              >
                <FaYahoo size="50" className="text-center" />
                <div className="tooltip">Yahoo</div>
              </a>
            )}

            {propData.skype && (
              <a
                rel="noreferrer"
                className="social-icon social-icon--skype"
                href={`skype:${propData.skype}`}
                target="_blank"
              >
                <FaSkype size="50" className="text-center" />
                <div className="tooltip">Skype</div>
              </a>
            )}
          </div>
        ) : null}
        <hr className="draw-line mob-noLine"></hr>
        <div className="">
          <ul className="list-unstyled horizantal-links mb-0 seo-links">
            <li>
              <Link to="/" title="Yosemite Hotels">
                Yosemite Hotels
              </Link>
            </li>
            <li>
              <Link to="/guestrooms" title="Yosemite Guestrooms">
                Yosemite Guestrooms
              </Link>
            </li>

            <li>
              <Link
                to="/reservations"
                target="_blank"
                title="Yosemite Hotel Reservations"
              >
                Yosemite Hotel Reservations
              </Link>
            </li>

            <li>
              <Link
                to={`/${propertyData?.city?.split(" ").join("-")}-attractions`}
                title="Attractions in Yosemite"
              >
                Attractions in Yosemite
              </Link>
            </li>

            <li>
              <Link to="/packages" title="Yosemite Packages">
                <li>Yosemite Packages</li>
              </Link>
            </li>
          </ul>
        </div>
        <hr className="draw-line"></hr>
        <div
          className={
            pathname.pathname === "/overview"
              ? "col-12 copyright-cont d-flex copySec"
              : "col-12 copyright-cont d-flex"
          }
        >
          <p className="copy-right-line mb-md-5 mb-lg-3 mb-xl-3 pb-2">
            Website Design, Development, and Digital Marketing&nbsp;{" "}
            <a
              rel="noreferrer"
              href="https://www.innsight.com/hospitality-website-design"
              target="_blank"
              className="text-decoration-none"
              style={{ color: "inherit" }}
            >
              <i>
                <u className="font-weight">Powered by INNsight.</u>
              </i>
            </a>
          </p>
          <p className="copy-right-line cp-2">
            Copyright © {new Date().getFullYear()} INNsight.com, Inc.
          </p>
        </div>
      </div>
      {/* wave error resolve  */}
      <label htmlFor="g-recaptcha-response" className="customWaveErrorResolve">
        g-recaptcha-response
      </label>
      <label
        htmlFor="g-recaptcha-response-1"
        className="customWaveErrorResolve"
      >
        g-recaptcha-response-1
      </label>
      <label
        htmlFor="g-recaptcha-response-2"
        className="customWaveErrorResolve"
      >
        g-recaptcha-response-2
      </label>
      {/* wave error resolve  */}
    </div>
  );
};

export default MainFooter;
