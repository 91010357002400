import React, { useEffect } from "react";
import "./reservationButton.scss";
import { FaConciergeBell } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { getSideNavSelector } from "../../../../Recoil/themeModule";
import { Link } from "react-router-dom";

const ReservationBotton = (props) => {
  const [sideNav, setSideNav] = useRecoilState(getSideNavSelector);
  useEffect(() => {
    localStorage.setItem("sideNav", "false");
  }, []);

  const openNav = () => {
    setSideNav(true);
    document.getElementById("mySidenav").style.width = "100%";
    document.getElementById("menuStatus").style.display = "none";
    document.querySelector("body").classList.toggle("menu_activated");
    setTimeout(() => {
      document
        .querySelector("#showCAonClickBtn")
        .classList.add("CAActiveOnBtn");
    }, 250);
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  };
  const closeNav = () => {
    setSideNav(false);
    document.getElementById("mySidenav").style.width = "0%";
    document.getElementById("menuStatus").style.display = "block";
    document.querySelector("body").classList.remove("menu_activated");
    document
      .querySelector("#showCAonClickBtn")
      .classList.remove("CAActiveOnBtn");
    document.getElementsByTagName("body")[0].style.overflowY = "scroll";
  };

  return (
    <div className="ReservationBtn">
      <div id="mySidenav" className="sidenav">
        <div className="close-container" title="Close" onClick={closeNav}>
          <div className="leftright"></div>
          <div className="rightleft"></div>
        </div>
        <div className="d-flex align-items-center cust-mrgn">
          <div className="container text-center mt-md-5 mt-2 mt-xl-1 mt-lg-2">
            <div className="text-center text-white book-direct-title">
              BOOK DIRECT & SAVE
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-md-none d-xl-block d-lg-block">
        <button
          type="button"
          title="Reservation"
          className="hm-reservationBtn"
          onClick={openNav}
        >
          Reservation
        </button>
      </div>
      <div className="d-block d-md-block d-xl-none d-lg-none">
        <Link to="/reservations" target="blank">
          <span className="d-none">Reservation</span>
          <FaConciergeBell
            size="50"
            className="mob-hm-reservation"
            id="resMobIcon"
          />
        </Link>
      </div>
    </div>
  );
};

export default ReservationBotton;
