import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import "./MainFooter.scss";
import { useRecoilState, useRecoilValue } from "recoil";
import LanguageTranslate from "../../../languageTest";
import { FaWheelchair } from "react-icons/fa";
import SocialIconComponent from "../Home/sections/socialIcons.js";
import {
  getSideNavSelector,
  propertyDataSelector,
  themeSelector,
} from "../../../Recoil/themeModule";
import footerLogo from "../../Hotellela/assets/Icons/HotelLelaWhite.svg";
import logo1 from "../../Hotellela/assets/Icons/hilton.svg";
import { getSubscribeEmailData } from "../../../DataLayer/datalayerUtilities";
import ReCAPTCHA from "react-google-recaptcha";
// signup
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";

import MyEmail from "../assets/Icons/MyEmail.svg";
import MyPhone from "../assets/Icons/MyPhone.svg";
import MyAddress from "../assets/Icons/MyAddress.svg";

const MainFooter = (allpropertydata) => {
  const [sideNav, setSideNav] = useRecoilState(getSideNavSelector);
  const recaptchaRef = useRef(null);
  const isIpad = useMediaQuery({
    query: "(min-width: 320px) and (max-width: 991px)",
  });
  const formSchema = Yup.object().shape({
    subscriber_email: Yup.string()
      .required("Please Enter Your Email Address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Please Enter Valid Email Address"
      ),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
  } = useForm(formOptions);

  const propertyData = useRecoilValue(propertyDataSelector);

  const themeSelectorData = useRecoilValue(themeSelector);
  const invisibleCaptchaKey = themeSelectorData.invisiblenoCaptchaSiteKey;
  const pathname = useLocation();

  const waveerro = document.querySelectorAll("h2.mb-3");

  for (let i = 0; i < waveerro.length; i++) {
    waveerro[i].textContent = "Yosemeite";
    waveerro[i].classList.add("d-none");
  }

  useEffect(() => {
    if (errors.subscriber_email) {
      alert(errors.subscriber_email.message);
    }
  }, [errors]);

  // Sojern Script
  useEffect(() => {
    setTimeout(() => {
      import("../../../Sojern.js").then(() => {
        console.log("Sojern.js imported");
      });
    }, 10000);
  }, []);
  // Sojern Script

  // Gtag Script
  // useEffect(() => {
  //   import("../../../gtag.js").then(() => {
  //     console.log("gtag.js imported");
  //   });
  // }, []);
  // Gtag Script

  const h1scroll = () => {
    const element = document.querySelector("h1");
    if (element) {
      if (window.screen.availWidth > 1199) {
        let info1topoffset = element.offsetTop;
        let checkAvail =
          document.querySelector("#showCAonClickBtn").offsetHeight || 115;
        window.scrollTo({
          top: info1topoffset - checkAvail + 60,
          behavior: "smooth",
        });
      } else {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (sideNav === false && pathname.pathname !== "/") {
      setTimeout(() => {
        // h1scroll();
      }, 2000);
    }
  }, [pathname.pathname]);

  const onSubmit = async (data) => {
    const logo_path = "";
    const subscriber_name = "";
    var dateWhen = Date().toLocaleString();
    const recaptchaValue = await recaptchaRef.current.executeAsync();
    const { email, property_name } = propertyData;

    const finalData = {
      ...data,
      logo_path,
      subscriber_name,
      dateWhen,
      property_name,
      email,
      captcha: recaptchaValue,
      theme: themeSelectorData.theme,
    };

    const response = await getSubscribeEmailData(finalData);

    if (response.data === "success") {
      alert("Thank You! You Have Successfully Subscribed To The Mailing List.");
      document.getElementById("subscriber_email").value = "";
    } else if (response.data === "Erremail") {
      alert(
        "Sorry, Your Email Address Is Already Subscribed. Please Enter Another Email Address."
      );
    } else if (response.data === "Invalid") {
      if (document.getElementById("subscriber_email").value == "") {
        alert("Enter Your Email Address.");
      } else {
        alert("Sorry, You Have Entered An Invalid Email Address.");
      }
    } else {
      alert(
        "We Are Sorry, We Have Experienced A Technical Issue. Please Try Again After Some Time."
      );
    }

    reset({
      subscriber_email: "",
    });
    if (recaptchaRef?.current) {
      recaptchaRef?.current.reset();
    }
  };

  return (
    <div className="Footer-Style-hotellela px-0 mt-lg-0 common-footer">
      <div className="container-fluid footer-component px-0">
        <div className="row text-center text-white p-0 m-0">
          <div className="col-12 d-flex flex-column flex-lg-row m-0 cls-wrapper">
            {/* contact info  */}
            <section className="hotel-lela-contact-info d-flex flex-column col-12 col-lg-4 mt-4 mt-lg-0">
              <p className="Phone-text Phone">
                <a
                  className="text-decoration-none"
                  href={`tel:${
                    propertyData &&
                    propertyData.primary_phone_no &&
                    propertyData.primary_phone_no.split("/")[1]
                  }`}
                >
                  <span className="mr-0">
                    <img src={MyPhone} className="mx-1" alt="Phone" />
                  </span>
                  {propertyData &&
                    propertyData.primary_phone_no &&
                    propertyData.primary_phone_no.split("/")[1]}
                </a>
              </p>
              <p className="Email Phone-text">
                <a
                  className="text-decoration-none"
                  href={`mailto:${propertyData.email}`}
                >
                  <span className="mr-1">
                    <img src={MyEmail} className="mx-1" alt="Email" />
                  </span>
                  {propertyData.email}
                </a>
              </p>
              <p className="address Phone-text">
                <Link className="d-flex">
                  <span className="mr-1">
                    <img src={MyAddress} className="mx-1" alt="Address" />
                  </span>
                  <span>
                    {propertyData?.street_address} {propertyData?.city}, NC{" "}
                    {propertyData?.zip_code}
                  </span>
                </Link>
              </p>
            </section>
            {/* contact info  */}

            {/* htoel lela logo  */}
            <section className="hotel-lela-logo col-12 col-lg-4">
              <Link to="/">
                <img
                  src={footerLogo}
                  alt="img"
                  className="hotelela-logo"
                  loading="lazy"
                />
              </Link>
            </section>
            {/* htoel lela logo  */}

            {/* subscription email */}
            {/* <section className="subscribe_wrapper d-flex col-12 col-lg-4">
              <div className="subscribe-input-text text-center text-white text-md-center d-flex flex-column">
                Subscribe to our newsletter
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="mt-3 btn-subscribe"
                >
                  <label
                    htmlFor="subscriber_email"
                    className="customWaveErrorResolve"
                  >
                    subscriber email
                  </label>

                  <input
                    id="subscriber_email"
                    placeholder="Enter Your Email Address"
                    className={`form-control form-controlled ltr-none bdr-left-rght-radius ${
                      errors.subscriber_email && "invalid"
                    }`}
                    {...register("subscriber_email", {})}
                    onKeyUp={() => {
                      trigger("subscriber_emails");
                    }}
                  />

                  <label htmlFor="g-recaptcha-response" className="d-none">
                    g-recaptcha-response
                  </label>
                  <label htmlFor="g-recaptcha-response-1" className="d-none">
                    g-recaptcha-response-1
                  </label>
                  <label htmlFor="g-recaptcha-response-2" className="d-none">
                    g-recaptcha-response-2
                  </label>

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    sitekey={invisibleCaptchaKey}
                    size="invisible"
                  />
                  <button className="footer-lela-btn" type="submit">
                    Sign up
                  </button>
                </form>
              </div>
            </section> */}
            {/* subscription email */}
          </div>
        </div>

        <label
          htmlFor="g-recaptcha-response"
          className="customWaveErrorResolve"
        >
          g-recaptcha-response
        </label>
        <label
          htmlFor="g-recaptcha-response-1"
          className="customWaveErrorResolve"
        >
          g-recaptcha-response-1
        </label>
        <label
          htmlFor="g-recaptcha-response-2"
          className="customWaveErrorResolve"
        >
          g-recaptcha-response-2
        </label>

        <div className="links-grid-container container my-4 col-12">
          <div className="links-row">
            {/* {propertyData.overview_status && (
              <li>
                <Link to="/overview">About Us</Link>
              </li>
            )} */}
            <li>
              <a
                href="https://www.hilton.com/en/p/global-privacy-statement/"
                target="_blank"
                rel="noreferrer"
              >
                Global Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://www.hilton.com/en/p/site-usage-agreement/"
                target="_blank"
                rel="noreferrer"
              >
                Site Usage Agreement
              </a>
            </li>

            <li>
              <a
                href="https://www.hilton.com/en/p/cookies-statement/"
                target="_blank"
                rel="noreferrer"
              >
                Cookies Statement
              </a>
            </li>
            <li>
              <a
                href="https://www.hilton.com/en/hilton-honors/personal-data-requests/"
                target="_blank"
                rel="noreferrer"
              >
                Personal Data Request
              </a>
            </li>
            <li>
              <a
                href="https://www.hilton.com/en/hilton-honors/personal-data-requests/"
                target="_blank"
                rel="noreferrer"
              >
                Do Not Sell My Information
              </a>
            </li>
            {propertyData.innsight_status === "1" && (
              <li>
                <Link to="/reviews">Reviews</Link>
              </li>
            )}

            {/* {propertyData.job_status && (
              <li>
                <Link to="/jobs">Careers</Link>
              </li>
            )} */}

            {propertyData.contact_us_status && (
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            )}

            {propertyData?.direction_status && (
              <li>
                <Link to="/directions">Directions</Link>
              </li>
            )}

            {propertyData.faq_status === "1" && (
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
            )}

            {/* {propertyData.is_personal_info === "1" && (
              <li>
                <Link to="/personal-info-request-form">
                  Personal Data Request
                </Link>
              </li>
            )} */}
            <li>
              <Link to="/sitemap">Sitemap</Link>
            </li>

            {/* {propertyData.is_cookies_policy === "1" && (
              <li>
                <Link to="/cookies">Cookie Policy</Link>
              </li>
            )}

            {propertyData.is_privacy_policy === "1" && (
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            )}

            {propertyData.is_terms_cond === "1" && (
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
            )} */}

            {propertyData.blogStatus === "1" && (
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            )}
          </div>
        </div>
        <div className="lelatrade text-center">
          <p>
            This website is maintained by Hotel Lela, Tapestry Collection by
            Hilton, a licensed user of certain proprietary trademarks and
            service marks of Hilton Hotels & Resorts®
          </p>
        </div>
        <div className="links-grid-container container mt-4 mb-4">
          <div className="links-row">
            <div className="">
              <div className="button-container flex-column flex-md-row my-0 my-md-0 logoSec">
                <div className="hilton1">
                  <a
                    href="https://www.hilton.com/en/hilton-honors/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-100 desktop-logo img-fluid my-4 my-md-2 py-2 my-xl-0 py-xl-0"
                      src={logo1}
                      height="4px"
                      alt="img"
                    />
                  </a>
                </div>

                <div className="cust-align-end PR lang-btn mt-0 mb-0 lang1">
                  <LanguageTranslate />
                  <label
                    htmlFor="goog-gt-votingInputSrcLang"
                    className="d-none"
                  >
                    one
                  </label>
                  <label
                    htmlFor="goog-gt-votingInputTrgLang"
                    className="d-none"
                  >
                    two
                  </label>
                  <label
                    htmlFor="goog-gt-votingInputSrcText"
                    className="d-none"
                  >
                    three
                  </label>
                  <label
                    htmlFor="goog-gt-votingInputTrgText"
                    className="d-none"
                  >
                    four
                  </label>
                  <label htmlFor="goog-gt-votingInputVote" className="d-none">
                    five
                  </label>
                </div>
                <div className="xs-mt-30">
                  {propertyData.accessibilty_status && (
                    <Link
                      to="/ada-accessibility-amenities-services-facilities"
                      className="wheelchair-m d-flex justify-content-center"
                    >
                      <span className="wheel-text d-flex button-ada text-decoration-none">
                        <FaWheelchair size="20" className="on-h-Icon mr-1" />
                        <span>Accessibility</span>
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isIpad && (
          <div className="container-fluid px-0">
            <div className="row col-12 mx-auto">
              <div className="col-md-12 col-xl-6 col-lg-6 col-12 px-0">
                <div className="mt-3 mt-lg-5 mt-xl-5 mt-md-1 ">
                  <SocialIconComponent />
                </div>
              </div>
              <div className="col-md-12 col-xl-6 col-lg-6 col-12 d-flex justify-content-center align-items-center mt-xl-2 mt-lg-2">
                <div className="button-container mt-3 mt-lg-5 mt-xl-5 mt-md-4 flex-column flex-md-row my-3 my-md-0 logoSec">
                  <div className="hilton1">
                    <a
                      href="https://www.hilton.com/en/hilton-honors/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="w-100 desktop-logo img-fluid my-4 my-md-2 py-2 my-xl-0 py-xl-0"
                        src={logo1}
                        alt="img"
                      />
                    </a>
                  </div>

                  <div className="cust-align-end PR lang-btn mt-0 mb-0 lang1">
                    <LanguageTranslate />
                    <label
                      htmlFor="goog-gt-votingInputSrcLang"
                      className="d-none"
                    >
                      one
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputTrgLang"
                      className="d-none"
                    >
                      two
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputSrcText"
                      className="d-none"
                    >
                      three
                    </label>
                    <label
                      htmlFor="goog-gt-votingInputTrgText"
                      className="d-none"
                    >
                      four
                    </label>
                    <label htmlFor="goog-gt-votingInputVote" className="d-none">
                      five
                    </label>
                  </div>
                  <div className="pb-3 mb-3 xs-mt-30 Accbtn">
                    {propertyData.accessibilty_status && (
                      <Link
                        to="/ada-accessibility-amenities-services-facilities"
                        className="wheelchair-m d-flex justify-content-center"
                      >
                        <span className="wheel-text d-flex button-ada text-decoration-none">
                          <FaWheelchair size="20" className="on-h-Icon mr-1" />
                          <span>Accessibility</span>
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={
            pathname.pathname === "/overview"
              ? "copywrite-msg text-center d-flex copy-right-bg copySec"
              : "copywrite-msg text-center d-flex copy-right-bg"
          }
        >
          <div className="col-12 col-md-12 col-xl-6 col-lg-6">
            <div className="copy-right-line1 p-2">
              Website Design, Development, and Digital Marketing{" "}
              <a
                target="_blank"
                href="https://www.innsight.com/hospitality-website-design"
                rel="noreferrer"
              >
                <u className="">
                  <i>Powered by INNsight</i>
                </u>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-12 col-xl-6 col-lg-6 p-2">
            <div>Copyright © {new Date().getFullYear()} INNsight.Com, Inc.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
